import React from 'react';
import Modal from 'react-modal';

import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import 'bootstrap/dist/css/bootstrap.css';

import Mask_Group_1 from './assets/MaskGroup1.png';
import Group_27431 from './assets/Group 27431@2x.png';
import Mask_Group_2 from './assets/Mask Group 2@2x.png';
import paper_plane_solid from './assets/paper-plane-solid@2x.png';
import lidya_nada_638295_unsplash from './assets/lidya-nada-638295-unsplash.png';
import users_class_light from './assets/users-class-light@2x.png';
import satellite_light from './assets/satellite-light@2x.png';
import file_chart_line_light from './assets/file-chart-line-light@2x.png';
import paul_hanaoka_608788_unsplash from './assets/paul-hanaoka-608788-unsplash.png';
import photo_1470309864661 from './assets/photo-1470309864661-68328b2cd0a5.png';
import janko_ferlic from './assets/janko-ferlic-150572-unsplash.png';
import top_banner from './assets/top_banner@2x.png';
import tempFile from './assets/RepeatGrid1@2x.png';
import rocket from './assets/rocket.png';

import './App.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const envFile = process.env;
Modal.setAppElement('#root');

function App() {

  const formInputInvitationEmail = React.createRef();
  const formInputRequestDemoEmail = React.createRef();
  const formInputRequestDemoName = React.createRef();
  const formInputRequestDemoCompany = React.createRef();

  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [modalIsOpen2, setModalIsOpen2] = React.useState(false);
  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [recaptchaToken2, setRecaptchaToken2] = React.useState("");

  const [invitationEmail, setInvitationEmail] = React.useState("");
  const [requestDemoEmail, setRequestDemoEmail] = React.useState("");
  const [requestDemoName, setRequestDemoName] = React.useState("");
  const [requestDemoCompany, setRequestDemoCompany] = React.useState("");

  function handleSubmit(event) {
    event.preventDefault();

    fetch('/api/values/invitation', {
      method: 'POST',
      body: JSON.stringify({ email: invitationEmail, googleToken: recaptchaToken }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    });

    setInvitationEmail('');
    formInputInvitationEmail.current.value = '';
    setModalIsOpen(true);
  }

  function handleSubmit2(event) {
    event.preventDefault();

    fetch('/api/values/requestDemo', {
      method: 'POST',
      body: JSON.stringify({
        email: requestDemoEmail,
        name: requestDemoName,
        company: requestDemoCompany,
        googleToken: recaptchaToken2
      }),
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    });
    setRequestDemoEmail('');
    setRequestDemoName('');
    setRequestDemoCompany('');
    formInputRequestDemoEmail.current.value = '';
    formInputRequestDemoName.current.value = '';
    formInputRequestDemoCompany.current.value = '';
    setModalIsOpen2(true);
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={envFile.REACT_APP_RECAPTCHA_KEY}>

      <div className="container-fluid p-0">
        <div className="row no-gutters justify-content-center align-items-center">
          <div className="col-12 text-center bg-banner" style={{ backgroundImage: `url(${top_banner})` }}>

          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-0">
        <section className="row no-gutters">
          <div className="col-12 text-center delivery-box-0-main" style={{ backgroundImage: `url(${tempFile})` }} >
            <img
              className="img-fluid delivery-logo"
              src={Group_27431}
              alt=""
            />
            <br />
            <p className="font-avenir">Retail Made Smart</p>
            <p className="delivery-box-0-desc px-3 px-md-0">
              RetailonTap believes in the power of technology to reduce the complexity in mundane retail jobs< br />< br />
              We transform old, manual ways of working into modern digital workflows
              </p>
          </div>
        </section>
      </div>

      <div className="container-fluid p-0 delivery-box-1 position-relative">
        <span className="delivery-text-below">Collaboration</span>
        <div className="row no-gutters">
          <div className="col-sm-12 col-md-6">
            <div className="delivery-box-1-left text-left">
              <h2 className="delivery-fw-900">
                Collaboration at your Finger Tips
              </h2>
              <p className="delivery-box-1-left-text mt-5">
                Spend more time getting things done. Organize your work, create reports, and discuss everything in one place.
              </p>

              <p className="delivery-box-1-left-text mt-4">
                <strong className="delivery-fw-900">Efficient teamwork for Retailers &amp; Suppliers</strong>
                <br />
                Save time by securely collaborating across teams, departments, offices and countries.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 text-sm-center text-md-right">
            <img className="img-fluid" src={Mask_Group_2} alt="" />
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-2 position-relative">
        <span className=" delivery-text-below delivery-text-below-light delivery-text-below-center ">Invitation</span>
        <div className="row no-gutters justify-content-center align-items-center delivery-box-2-mt">
          <div className="col-12 text-center ">
            <h2 className="text-light mt-1 mt-sm-5 delivery-fw-900">
              Get An Early Invitation
          </h2>
            <p className="text-light mt-5 font-large delivery-fw-300">
            RetailonTap is currently in development. Register your interest to <span className="d-none d-md-inline">to <br /> ​be an Early adopter</span>
            </p>
            <div className="text-center mt-5 d-inline-block">
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <div className="delivery-box-2-form-text-box">
                    <input
                      type="email"
                      onChange={e => { setInvitationEmail(e.target.value) }}
                      ref={formInputInvitationEmail}
                      name="email"
                      required="required"
                      className="form-control delivery-box-2-form-input"
                      placeholder="Enter your work email"
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary p-1 delivery-box-2-form-btn"
                      type="submit"

                    >
                      <img src={paper_plane_solid} width="30" alt="" />
                      <span className="delivery-box-2-form-btn-title d-block mt-1">
                        Send
                      </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-3">
        <div className="row no-gutters justify-content-center align-items-center">
          <div className="col-12 text-center">
            <img
              src={lidya_nada_638295_unsplash}
              className="img-fluid w-100"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-4 position-relative">
        <span className=" delivery-text-below delivery-text-below-center mt-1">Simple</span>
        <div className="row no-gutters justify-content-center align-items-center delivery-box-2-mt">
          <div className="col-12 text-center mt-sm-5 mt-md-0">
            <h2 className="delivery-fw-900 mt-sm-4 mt-md-0">
              What it is
            </h2>
            <div className="mt-5 ml-auto mr-auto delivery-box-4-main font-large pr-2 pr-md-0">
              <div className="float-left pr-sm-3">
                <img
                  className="img-fluid w-50"
                  src={users_class_light}
                  alt=""
                />
              </div>
              <div className="w-75 ml-auto text-left delivery-fw-300">
                An interactive workspace, for
              <strong className="delivery-fw-900"> efficient collaboration and better negotiaton, enabling </strong>
                more accurate and smarter decisions
              </div>
              <br />
              <br />
              <div className="float-left pr-sm-3">
                <img
                  className="img-fluid w-50 ml-2"
                  src={satellite_light}
                  alt=""
                />
              </div>
              <div className="w-75 ml-auto text-left delivery-fw-300">
                An intelligent space that boosts and enhances the use of visual content, which is the future of this industry's way of communication
              </div>
              <br />
              <br />
              <div className="float-left pr-sm-3">
                <img
                  className="img-fluid w-50 ml-3"
                  src={file_chart_line_light}
                  alt=""
                />
              </div>
              <div className="w-75 ml-auto text-left delivery-fw-300">
                Generates valuable data that can be visualised, monitored and efficiently be used for business inteligence and boosting efficiency
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-5 position-relative">
        <span className=" delivery-text-below delivery-text-below-center mt-1">Retailontap</span>
        <div className="row no-gutters align-items-center delivery-box-2-mt">
          <div className="col-12 text-center">
            <h2 className="delivery-box-5-title delivery-fw-900">
              What We Do
          </h2>
          </div>
          <div className="col-sm-12 col-lg-1 col-xl-2"></div>
          <div className="col-sm-12 col-lg-10 col-xl-8">
            <div
              className="row no-gutters mt-5 delivery-box-5-gallery rounded shadow-lg h-100 "
            >
              <div className="col-sm-12 col-md-4">
                <img
                  className="img-fluid delivery-box-5-gallery-img"
                  src={paul_hanaoka_608788_unsplash}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-4 text-center px-5 px-sm-3">
                <h3 className="mt-4 mx-1 delivery-box-5-gallery-title delivery-fw-900">
                  Instant Reactions
              </h3>
                <i className="delivery-box-5-desc">
                  "I want it now" - Verruca Salt
              </i>
                <p className="delivery-box-5-text delivery-box-5-text-mt-more">
                  We provide a vehicle to view, snap and upload trends on the go anywhere around the world
              </p>
              </div>
              <div className="col-sm-12 col-md-4">
                <img
                  className="img-fluid delivery-box-5-gallery-img"
                  src={photo_1470309864661}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-4 text-center px-5 px-sm-3">
                <h3 className="mt-4 mx-1 delivery-box-5-gallery-title delivery-fw-900">
                  Drive Communication
              </h3>
                <i className="delivery-box-5-desc">
                  "Communication is the key for any global business" - Anita Roddick
              </i>
                <p className="delivery-box-5-text">
                  Share current or new trends with your preselected supplier base for instant reactions.
              </p>
              </div>
              <div className="col-sm-12 col-md-4">
                <img
                  className="img-fluid delivery-box-5-gallery-img"
                  src={janko_ferlic}
                  alt=""
                />
              </div>
              <div className="col-sm-12 col-md-4 text-center px-5 px-sm-3">
                <h3 className="mt-4 mx-1 delivery-box-5-gallery-title delivery-fw-900">
                  Boost Efficiency + Maximise Profits
              </h3>
                <i className="delivery-box-5-desc">
                  "Efficiency is intelligent laziness" - Anonymous
              </i>
                <p className="delivery-box-5-text">
                  Our aim is boost efficiency and maximise profits by eradicting unproductive supplier meetings and reacting to market opportunities.
              </p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-lg-1 col-xl-2"></div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-6 rounded">
        <div className="row no-gutters justify-content-center align-items-center">
          <div className="col-12 text-center bg-mobiles" style={{ backgroundImage: `url(${Mask_Group_1})` }}>
          </div>
        </div>
      </div>

      <div className="container-fluid p-0 delivery-box-2 position-relative delivery-box-footer">
        <span className=" delivery-text-below delivery-text-below-light delivery-text-below-center mt-1">Demo</span>
        <div className="row no-gutters justify-content-center align-items-cente delivery-box-2-mt">
          <div className="col-12 text-center ">
            <h2 className="text-light mt-1 mt-sm-5">
              Request Demo
          </h2>
            <p className="text-light mt-5 font-large delivery-fw-300 px-3 pt-5 pt-md-0 px-md-0">
              See how Retailontap helps you improve collaboration with your team and your suppliers <br />
              and provide a seamless experience.
          </p>

            <div className="text-center mt-5 d-inline-block">
              <form onSubmit={handleSubmit2}>
                <div className="input-group mt-5 mt-md-0">
                  <div className="delivery-box-2-form-text-box">
                    <input
                      type="text"
                      ref={formInputRequestDemoName}
                      className="form-control delivery-box-2-form-input"
                      placeholder="Name*"
                      required="required"
                      name="name"
                      onChange={e => { setRequestDemoName(e.target.value) }}
                    />
                    <input
                      type="text"
                      className="form-control delivery-box-2-form-input mt-1"
                      placeholder="Company*"
                      ref={formInputRequestDemoCompany}
                      required="required"
                      name="company"
                      onChange={e => { setRequestDemoCompany(e.target.value) }}
                    />
                    <input
                      type="email"
                      name="email"
                      ref={formInputRequestDemoEmail}
                      className="form-control delivery-box-2-form-input mt-1"
                      placeholder="Work Email*"
                      required="required"
                      onChange={e => { setRequestDemoEmail(e.target.value) }}
                    />
                  </div>
                  <div className="input-group-append">
                    <button
                      className="btn btn-secondary p-1 delivery-box-2-form-btn"
                      type="submit"
                    >
                      <img src={paper_plane_solid} width="30" alt="" />
                      <span className="delivery-box-2-form-btn-title d-block mt-1"
                      >Send</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="text-center">
          <span className="text-light delivery-fw-300 delivery-copy-right">© 2020 Retailontap . All Rights Reserved.</span>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className="text-center delivery-modal-box">
          <img src={rocket} width="100" alt="" />
          <p className="mt-5 delivery-modal-box-title">
            Your early invitation has been sent
          </p>
          <button
            className="btn btn-secondary mt-5 p-1 delivery-Okay-btn"
            type="button"
            onClick={() => setModalIsOpen(false)}>Ok</button>
        </div>
      </Modal>
      <Modal
        isOpen={modalIsOpen2}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
      >
        <div className="text-center delivery-modal-box">
          <img src={rocket} width="100" alt="" />
          <p className="mt-5 delivery-modal-box-title">
            Your request has been sent
          </p>
          <button
            className="btn btn-secondary mt-5 p-1 delivery-Okay-btn"
            type="button"
            onClick={() => setModalIsOpen2(false)}>Ok</button>
        </div>
      </Modal>      
      <GoogleReCaptcha onVerify={token => setRecaptchaToken(token)} />
      <GoogleReCaptcha onVerify={token => setRecaptchaToken2(token)} />
    </GoogleReCaptchaProvider>
  );
}

export default App;
